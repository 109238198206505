@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    padding: 0;
}


.header {
  display:flex;
  overflow:hidden;
  white-space:nowrap;
  /* font-size: 20px; */
}

.text-center {
  text-align: center;
}
  
  .header:first-of-type {    /* For increasing performance 
                         ID/Class should've been used. 
                         For a small demo 
                         it's okaish for now */
    animation: showup 7s infinite;
  }
  
  .header:last-of-type {
    width:0px;
    animation: reveal 7s infinite;
  }
  
  .header:last-of-type span {
    margin-left:0px;
    animation: slidein 7s infinite;
  }
  
  @keyframes showup {
      0% {opacity:0;}
      20% {opacity:1;}
      80% {opacity:1;}
      100% {opacity:0;}
  }
  
  @keyframes slidein {
      0% { margin-left:0px; }
      20% { margin-left:0px; }
      35% { margin-left:10px; }
      100% { margin-left:10px; }
  }
  
  @keyframes reveal {
      0% {opacity:0;width:0px;}
      20% {opacity:1;width:0px;}
      30% {width:355px;}
      80% {opacity:1;}
      100% {opacity:0;width:400px;}
  }
  
